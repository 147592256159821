import { makeStyles } from '@material-ui/styles'
import renderBlok from '@renderBlok'
import { Button, ContentContainer, Icon, PageContainer } from '@system'
import React, { useState } from 'react'
import SbEditable from 'storyblok-react'

const useStyles = makeStyles((theme) => ({
  quoteCarouselRoot: {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
  },
  quoteCarouselSlidesContainer: {
    display: 'flex',
    overflow: 'hidden',
  },
  quoteCarouselSlide: {
    opacity: '0',
    flex: 'none',
    transition: 'opacity 2s',
    width: '100%',
  },
  quoteCarouselSlideActive: {
    opacity: '1',
    order: '-1',
  },
  quoteCarouselNav: ({ quoteVariant }) => ({
    bottom: '64px',
    display: 'flex',
    justifyContent: 'center',
    left: '0',
    position: 'absolute',
    right: '0',
    zIndex: '3',
    [theme.breakpoints.up('md')]: {
      bottom: '128px',
      justifyContent: quoteVariant && 'flex-start',
    },
  }),
  quoteCarouselButtonContainer: ({ quoteVariant }) => ({
    display: 'flex',
    gap: '16px',
    [theme.breakpoints.up('md')]: {
      marginLeft: quoteVariant && '252px',
    },
  }),
  quoteCarouselButton: {
    backgroundColor: 'black',
    borderRadius: '50%',
    height: '36px',
    minWidth: 'unset',
    padding: '0',
    width: '36px',
    '& svg': {
      height: '36px',
      width: '36px',
    },
    [theme.breakpoints.down('md')]: {
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      },
    },
  },
}))

const QuoteCarousel = ({ blok }) => {
  const { quotes, quoteVariant } = blok
  const [activeIndex, setActiveIndex] = useState(0)
  const classes = useStyles({ quoteVariant })

  const handleActiveIndex = (nextOrPrev) => {
    if (nextOrPrev === 'next') {
      if (quotes.length - 1 === activeIndex) {
        setActiveIndex(0)
        return
      }
      setActiveIndex((prevActiveIndex) => prevActiveIndex + 1)
    } else {
      if (activeIndex === 0) {
        setActiveIndex(quotes.length - 1)
        return
      }
      setActiveIndex((prevActiveIndex) => prevActiveIndex - 1)
    }
  }

  const renderSlides = () =>
    quotes.map((quote, index) => {
      let quoteCarouselSlideClasses = [classes.quoteCarouselSlide]

      if (activeIndex === index) {
        quoteCarouselSlideClasses.push(classes.quoteCarouselSlideActive)
      }
      return (
        <div className={quoteCarouselSlideClasses.join(' ')} key={quote._uid}>
          {renderBlok(quote, {
            quoteCarouselVariant: quoteVariant,
          })}
        </div>
      )
    })

  return (
    <SbEditable content={blok}>
      <PageContainer>
        <div className={classes.quoteCarouselRoot}>
          {quotes?.length > 0 && (
            <div className={classes.quoteCarouselSlidesContainer}>
              {renderSlides()}
            </div>
          )}
          <ContentContainer className={classes.quoteCarouselNav}>
            <div className={classes.quoteCarouselButtonContainer}>
              <Button
                className={classes.quoteCarouselButton}
                onClick={() => handleActiveIndex('prev')}
              >
                <Icon>ChevronLeft</Icon>
              </Button>
              <Button
                className={classes.quoteCarouselButton}
                onClick={() => handleActiveIndex('next')}
              >
                <Icon>ChevronRight</Icon>
              </Button>
            </div>
          </ContentContainer>
        </div>
      </PageContainer>
    </SbEditable>
  )
}

export default QuoteCarousel
